<template>
  <v-card elevation="12" min-height="600">
    <v-card-title>Report Ricognizioni passate</v-card-title>
    <br />
    <v-container>
      <v-card-text>
        <ReportItem v-for="item of scanAssets" :key="item.ID" :scanAsset="item"></ReportItem>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
// @ is an alias to /src

import ReportItem from '../../components/ReportItem.vue';

export default {
  name: "Report",

  components: {
    ReportItem
  },

  data: () => ({

    scanAssets: null,
  }),

  methods: {

    fetchScanAssets(){

      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/scanAssets", {
          headers,
        })
        .then((response) => {
          console.log(response);

          this.scanAssets = response.data;

          // Check if a refresh after 10 sec is needed (some item is waiting for report generation)
          for(let s of this.scanAssets){
            if(s.STATE === 'reporting'){
              console.log('refreshing because we are waiting for report')
              setTimeout(this.fetchScanAssets, 10000);
            }
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // this.permSnackbar = true;
          // this.snackbarColor = "error";
          // this.errorText = error.response.data;
          // this.timeout= -1;
        });
    },

  },

  created: function () {

    // Fetch scanAssets and their reports
    this.fetchScanAssets();

  },

  beforeDestroy: function() {}
};
</script>