<template>
  <v-container>
    <v-row no-gutters>
      <v-card class="pa-2" elevation="5">
        Ricognizione inventariale n.{{ scanAsset.ID }} <br />
        Stato: {{ scanAsset.STATE }} <br />
        Data inizio:
        {{ new Date(scanAsset.STARTDATE).toLocaleDateString("it-IT") }} <br />
        Data fine:
        {{ new Date(scanAsset.ENDDATE).toLocaleDateString("it-IT") }} <br />
        <div v-if="scanAsset.STATE === 'reporting'" class="text-center">
          Generazione dei report in corso...
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <div v-for="r in scanAsset.reports" :key="r.id">
            <v-btn color="primary" class="ma-2" @click="LoadReport(r.FILENAME)">
              {{ r.FILENAME }}</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ReportItem",

  props: {
    scanAsset: Object,
  },

  data: () => {
    return {
      patrimonioAPI: process.env.VUE_APP_PATRIMONIO_API,
    };
  },
  computed: {},
  created: function () {},

  methods: {
    LoadReport(filename) {
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/scanAssets/reports/" +
            filename,
          {
            // This is very important to avoi white page pdf
            responseType: "arraybuffer",
            headers,
          }
        )
        .then((response) => {
          let contentType = response.headers["content-type"];
          console.log(contentType);
          let blob = new Blob([response.data], { type: contentType });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // this.permSnackbar = true;
          // this.snackbarColor = "error";
          // this.errorText = error.response.data;
          // this.timeout= -1;
        });
    },
  },
};
</script>